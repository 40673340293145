import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DbCheckPanel from '../Panels/DbCheckPanel';
import '../../Details.scss';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';

function DbChecks({
  moduleName, subType, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) {
  const [dbCheckDetailArray, setDbCheckDetailArray] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setDbCheckDetailArray(records);
    setValue(0);
  }, [records]);
  return (
    <div id="details__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType={subType} />}
      {!hideTabs && (
      <CustomTabs
        id="details__body_tabs"
        tabIndex={value}
        setTabIndex={setValue}
        tabArray={dbCheckDetailArray}
      />
      )}
      {dbCheckDetailArray.map((item, index) => (
        <DbCheckPanel
          key={item.id}
          value={value}
          index={index}
          item={item}
          createdAt={item.createdAt}
          status={item.apiStatus}
          hideCreatedAtAndStatus={hideCreatedAtAndStatus}
        />
      ))}
    </div>
  );
}

DbChecks.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

DbChecks.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};
export default DbChecks;
