import React from 'react';
import PropTypes from 'prop-types';

import './ChevronIcon.scss';

function ChevronIcon({ classname }) {
  return <div id="chevron_icon" className={classname} />;
}

ChevronIcon.defaultProps = {
  classname: '',
};

ChevronIcon.propTypes = {
  classname: PropTypes.string,
};

export default ChevronIcon;
