import React from 'react';
import PropTypes from 'prop-types';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import { MissingRecordComponent } from '../Cards/Common';

function DefaultPanel({
  value, index, createdAt, url, isMissing, hideCreatedAtAndStatus,
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <div id="details__tab__container">
        {!isMissing
          ? (
            <>
              {!hideCreatedAtAndStatus && <CreatedAtAndStatus createdAt={createdAt} />}
              <div id="details__tab__details__container">
                <div id="details__tab__details__body">
                  <div>
                    <div id="details__tab__details__body__table_container">
                      <table>
                        <tr>
                          <td className="error">
                            Configuration missing for
                            {' '}
                            {url}
                            . Please contact

                            <br />
                            HyperVerge support
                          </td>
                        </tr>
                      </table>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <MissingRecordComponent />
          )}
      </div>
    </div>
  );
}

DefaultPanel.defaultProps = {
  createdAt: '',
  url: '',
  isMissing: false,
  hideCreatedAtAndStatus: false,
};

DefaultPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  createdAt: PropTypes.string,
  url: PropTypes.string,
  isMissing: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default DefaultPanel;
