import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import redirectArrow from '../../../../assests/icons/redirectArrow.svg';

const RedirectCardContent = ({
  title, content, redirectLink, customStyles, isHovered, fadeIn,
}) => (
  <div id="content_container" className={fadeIn ? 'fade-in' : ''}>
    <div
      id="content_container__title_container"
      style={{ color: isHovered ? get(customStyles, 'fontColorOnHover', '') : '' }}
    >
      <p id="content_container__title">{title}</p>
      {redirectLink && <img src={redirectArrow} alt="redirect" />}
    </div>
    <div id="content_container__content_container">
      <span id="content_container__content_value">{content}</span>
    </div>
  </div>
);

RedirectCardContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  redirectLink: PropTypes.string.isRequired,
  customStyles: PropTypes.object.isRequired,
  isHovered: PropTypes.bool.isRequired,
  fadeIn: PropTypes.bool.isRequired,
};

export default RedirectCardContent;
