import { set, sample } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { updateWorkflowList, updateHomePageData } from '../../reducers/user';
import useShowErrorAlert, {
  useFormatAnalyticsData,
  useFormatPerformanceAnalyticsData,
} from '../../utils/lib';
import AlertCode from '../../constants/alertCodes';
import './Main.scss';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import getWorkflows from '../../api/workflow';
import {
  PERFORMANCE_METRIC_EVENTS,
} from '../../config';
import errorCode from '../../constants/errorCode';
import { isNumber } from '../../utils/helpers';
import useGetUserPermissions from '../../Permissions/hooks';
import { getHomePageConfigApi } from '../../api/s3Apis';
import productRedirectFunctions from '../../utils/productRedirectFunctions';
import screenNames from '../../constants/screenNames';
import HeaderSection from './Home/Sections/HeaderSection';
import VideoSection from './Home/Sections/VideoSection';
import FAQSection from './Home/Sections/FAQSection';

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const showErrorAlert = useShowErrorAlert();
  const { allowedPermissions } = useGetUserPermissions();

  const currentAppId = useSelector(
    (state) => state.user.credState?.current?.appId,
  );
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const [isRudderStackDataSent, setIsRudderStackDataSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setFadeIn(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const fetchWorkflows = async () => {
    const worklowFetchStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/workflow',
      metaData: {
        appId: currentAppId,
      },
    };
    try {
      const workflowList = await getWorkflows(currentAppId);
      set(eventObj, 'statusCode', 200);
      dispatch(updateWorkflowList(workflowList));
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(
        `${errorCode.HOME_ERROR_FETCHING_WORKFLOWS} - ${error}`,
        {
          extra: {
            errorMessage: AlertCode.ERROR_FETCHING_WORKFLOWS,
          },
        },
      );
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_WORKFLOWS });
    }
    const processingTime = performance.now() - worklowFetchStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.FETCH_WORKFLOW;
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  const redirectToVKYC = () => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_VKYC_LINK_CLICK, screenNames.HOME,
    );
    productRedirectFunctions.VKYCPortal(navigate);
  };

  const filterHomePageConfig = (config, userPermissions) => {
    const redirectCards = [];
    const videoCards = [];
    const FAQCards = [];

    const addUniqueCard = (card, cardArray) => {
      const exists = cardArray.some((item) => JSON.stringify(item) === JSON.stringify(card));
      if (!exists) {
        cardArray.push(card);
      }
    };

    config.redirectCardsData.forEach((card) => {
      if (card.permissions.some((permission) => userPermissions.includes(permission))) {
        addUniqueCard(card, redirectCards);
      }
    });

    config.videoCards.forEach((card) => {
      if (card.permissions.some((permission) => userPermissions.includes(permission))) {
        addUniqueCard(card, videoCards);
      }
    });

    config.FAQCardsData.forEach((card) => {
      if (card.permissions.some((permission) => userPermissions.includes(permission))) {
        addUniqueCard(card, FAQCards);
      }
    });

    return {
      redirectCards,
      videoCards,
      FAQCards,
    };
  };

  const getHomePageConfig = async () => {
    try {
      const response = await getHomePageConfigApi();
      const { data } = response;
      const {
        redirectCards,
        videoCards,
        FAQCards,
      } = filterHomePageConfig(
        data, allowedPermissions,
      );
      dispatch(updateHomePageData({
        redirectCards,
        videoCards,
        FAQCards,
        welcomeEmoji: sample(data.welcomeEmojis),
      }));
    } catch (error) {
      Sentry.captureException(
        `${errorCode.ERROR_FETCHING_HOMEPAGE_CONFIG} - ${error}`,
        {
          extra: {
            errorMessage: AlertCode.ERROR_FETCHING_HOMEPAGE_CONFIG,
          },
        },
      );
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_HOMEPAGE_CONFIG });
    }
  };

  useEffect(() => {
    getHomePageConfig();
  }, []);

  useEffect(() => {
    if (currentAppId) {
      fetchWorkflows();
    }
  }, [currentAppId]);

  useEffect(() => {
    if (!isRudderStackDataSent) {
      formatAnalyticsData(
        email,
        clientId,
        rudderstackEvents.DASHBOARD_HOMEPAGE_LAUNCH,
        'Home',
      );
      setIsRudderStackDataSent(true);
    }
  });

  const environment = process.env.REACT_APP_ENV;

  if (environment === 'local' || environment === 'dev') {
    const element = document.getElementById('ds-clickwrap');
    if (element) {
      element.remove();
    }
  }

  return (
    <div id="main__home_container__body__generic">
      <div id="main__home_container__body__container">
        <div id="main__home_container__body__container__content">
          <div id="home_page" className="fade_in">

            {/* welcome and redirectCards */}
            <HeaderSection
              redirectToVKYC={redirectToVKYC}
              loading={loading}
              fadeIn={fadeIn}
            />

            {/* videoCards */}
            <VideoSection loading={loading} fadeIn={fadeIn} />

            {/* FAQ */}
            <FAQSection fadeIn={fadeIn} loading={loading} />

          </div>
        </div>
      </div>
    </div>
  );
}

// old version

/* <Grid container>
<Grid item xs={12}>
  <h2 className="font-design-heading">Home</h2>
</Grid>
<Grid item xs={12}>
  <span className="font-design-sub-heading">
    Get started with HyperVerge
  </span>
</Grid>
<DemoCard />
<Grid item xs={6}>
  <Grid container>
    <div className="modal__Rectangle-52">
      <Grid item xs={6}>
        <span className="modal__heading">
          Integrate SDK
        </span>
      </Grid>
      <Grid item xs={12} className="modal__sub_description_container">
        <span className="modal__sub_description">
          Integrate our battle-tested web and mobile SDKs and get started right away
        </span>
      </Grid>
      <Grid item xs={12} className="modal__link_container">
        <Link
          color="inherit"
          onClick={() => {
            formatAnalyticsData(email,
            clientId, rudderstackEvents.DASHBOARD_HOME_DOCUMENTATION_CLICK, 'Drawer');
            window.open(`${DOCUMENTATION_PORTAL_URL}/?jwt=${docToken}`);
          }}
          className="modal__link"
          id="modal__link_documentation"
        >
          Browse the Documentation
          <img src={arrow} alt="arrow" className="modal__link_arrow" />
        </Link>
      </Grid>
    </div>
  </Grid>
</Grid>
</Grid>

<div id="main__container__body__new_main">
        {
          (authorizedProducts && authorizedProducts.includes(authorisedProductsMapping.VKYCPortal))
            ? <VideoKycCard />
            : <></>
        }
      </div> */
