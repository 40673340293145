import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import VideoCards from '../Cards/VideoCards';

function VideoSection(
  {
    loading,
    fadeIn,
  },
) {
  const videoCardData = useSelector((state) => get(state, 'user.homePageData.videoCards', []));
  return (
    <>
      <div id="section_two_videoCard_container">
        <div id="videoCard_header">
          <span>Learn about our product</span>
        </div>
        <div id="videoCard_container">
          <VideoCards
            data={videoCardData}
            loading={loading}
            fadeIn={fadeIn}
          />
        </div>
      </div>
    </>
  );
}

VideoSection.propTypes = {
  loading: PropTypes.bool.isRequired,
  fadeIn: PropTypes.bool.isRequired,
};

export default VideoSection;
