import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import authorisedProductsMapping from '../../../../constants/authorisedProductsMapping';

function WelcomeCard({ onRedirect, welcomeEmoji }) {
  const userName = useSelector((state) => state.user.name);
  const authorizedProducts = useSelector(
    (state) => state.user.authorizedProducts,
  );

  return (
    <div id="home_page__welcome_container">
      <div id="home_page__welcome_container__content">
        <span>
          Welcome back
          {userName && `, ${startCase(userName)}`}
        </span>
        {welcomeEmoji
        && (
        <div id="home_page__welcome_container__content__emoji_container">
          <img src={welcomeEmoji} alt="hello" />
        </div>
        )}
      </div>
      {authorizedProducts.includes(authorisedProductsMapping.VKYCPortal)
       && <p onClick={onRedirect} id="switch_to_vkyc" aria-hidden>Switch to VKYC</p>}
    </div>
  );
}

WelcomeCard.propTypes = {
  onRedirect: PropTypes.func.isRequired,
  welcomeEmoji: PropTypes.string.isRequired,
};
export default WelcomeCard;
