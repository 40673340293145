import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './VideoCards.scss';
import HomeVideoCardPlayButton from '../../../../assests/icons/HomeVideoCardPlayButton.svg';
import CustomIcons from './customIcons.svg';
import Shimmer from '../../../Common/animations/shimmer/ShimmerEffect';

function VideoCards({ data, loading, fadeIn }) {
  const [playingId, setPlayingId] = useState([]);

  const playerOptions = {
    controls: [
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen',
    ],
    settings: ['speed'],
    captions: { active: true, update: true },
    speed: { selected: 1, options: [0.5, 1, 1.5, 2] },
    iconUrl: CustomIcons,
    iconPrefix: 'plyr',
    volume: 50,
  };

  const handlePlay = (id) => {
    if (!playingId.includes(id)) {
      setPlayingId([...playingId, id]);
    }
  };

  const videoSources = data.map((video) => ({
    id: video.id,
    thumbnailUrl: video.thumbnailUrl,
    shimmerColor: video.shimmerColor,
    source: {
      type: 'video',
      title: `Video ${video.id}`,
      sources: [
        {
          src: video.videoFileUrl,
          type: 'video/mp4',
          size: 720,
        },
      ],
      tracks: video.subtitleFileUrl
        ? [
          {
            kind: 'captions',
            label: 'English',
            srclang: 'en',
            src: video.subtitleFileUrl,
            default: true,
          },
        ]
        : [],
    },
  }));

  return (
    <div
      id="videoCard_container"
      style={{
        ...(loading ? { justifyContent: 'space-between' } : {}),
      }}
    >
      {loading ? (
        data.map((video) => (
          <Shimmer
            key={video.id}
            width="418px"
            height="240px"
            borderRadius="8px"
            backgroundColor={video.shimmerColor || '#f0f0f0'}
          />
        ))
      ) : (
        videoSources.map(({
          id, thumbnailUrl, source,
        }) => (
          <div
            key={id}
            id="videoCard_body"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
            }}
            className={fadeIn ? 'fade-in' : ''}
            onClick={() => handlePlay(id)}
            role="button"
            tabIndex={0}
            aria-hidden
          >
            {!playingId.includes(id) ? (
              <div id="videoCard_body__overlay" className={fadeIn ? 'fade-in' : ''}>
                <div id="playButton_container">
                  <img src={HomeVideoCardPlayButton} alt="Play" />
                </div>
              </div>
            ) : (
              <Plyr source={source} options={playerOptions} />
            )}
          </div>
        ))
      )}
    </div>
  );
}

VideoCards.defaultProps = {
  data: [],
  fadeIn: false,
  loading: false,
};

VideoCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      videoFileUrl: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
      subtitleFileUrl: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  fadeIn: PropTypes.bool,
};

export default VideoCards;
