import React from 'react';
import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';

import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { ToolTip } from 'clm-components';
import { ReactComponent as FlagIcon } from '../../../../assests/icons/flag.svg';

function StatusTimelineViewItem({
  time, status, end, createdAt, failureReason,
}) {
  return (
    <TimelineItem data-date={createdAt} className="application_details__timeline_view__item__container status">
      <TimelineContent className="application_details__timeline_view__timeline_content__time status">
        <div className="application_details__timeline_view__timeline_content__time_container status">
          <p>{time}</p>
        </div>
      </TimelineContent>
      <TimelineSeparator className="application_details__timeline_view__separator status">
        <TimelineDot />
        {!end && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className="application_details__timeline_view__timeline_content__module status">
        <div className="application_details__timeline_view__timeline_content__module_container">
          <div className={`application_details__timeline_view__timeline_content__module_container__overview status ${status}`}>
            <p>
              {startCase(status)}
              {failureReason && (
              <>
                <ToolTip title={failureReason} placement="top">
                  <FlagIcon className="application_details__timeline_view__timeline_content__module_flag_icon" />
                </ToolTip>
              </>
              )}
            </p>
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

StatusTimelineViewItem.defaultProps = {
  end: false,
  failureReason: '',
};

StatusTimelineViewItem.propTypes = {
  time: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  end: PropTypes.bool,
  createdAt: PropTypes.string.isRequired,
  failureReason: PropTypes.string,
};

export default StatusTimelineViewItem;
