import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import '../index.scss';
import './index.scss';
import CommonHeader from '../CommonHeader';
import CustomTabs from '../../../../../Common/Utils/CustomTabs';
import Panel from './Panel';

function GenericCardTypeV2({
  moduleId, moduleName, subType, records, hideHeader, hideTabs,
  hideCreatedAtAndStatus,
}) {
  const [attemptsArray, setAttemptsArray] = useState([]);
  const [selectedAttemptIndex, setSelectedAttemptIndex] = useState(0);

  useEffect(() => {
    setAttemptsArray(records);
    setSelectedAttemptIndex(0);
  }, [records]);
  return (
    <div className="generic_common_card__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType={subType} />}
      {!hideTabs && (
      <CustomTabs
        className="generic_common_card__body_tabs"
        tabIndex={selectedAttemptIndex}
        setTabIndex={setSelectedAttemptIndex}
        tabArray={attemptsArray}
        tabIdPrefix={`generic_common_card_${moduleId}_tab`}
        ariaControlsPrefix={`generic_common_card_${moduleId}_tab_panel`}
      />
      )}
      {attemptsArray.map((item, index) => (
        <Panel
          key={item.id}
          selectedAttemptIndex={selectedAttemptIndex}
          index={index}
          item={item}
          moduleId={moduleId}
          createdAt={item.createdAt}
          status={item.status}
          hideCreatedAtAndStatus={hideCreatedAtAndStatus}
        />
      ))}
    </div>
  );
}

GenericCardTypeV2.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

GenericCardTypeV2.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default GenericCardTypeV2;
