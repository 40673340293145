import React, { useState } from 'react';

import PropTypes from 'prop-types';
import '../../Details.scss';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';
import MultiTablePanel from '../Panels/MultiTablePanel';

function MultiTableCard({
  moduleName, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <div id="multi_table_card__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType="multiTableCard" />}
      <div id="multi_table_card__container__section">
        {!hideTabs && (
        <CustomTabs
          id="details__body_tabs"
          tabArray={records}
          tabIndex={currentTabIndex}
          setTabIndex={setCurrentTabIndex}
        />
        )}
        {
            records && records.length > 0 && records.map(
              (record, index) => (
                <MultiTablePanel
                  index={index}
                  currentTabIndex={currentTabIndex}
                  key={record.requestId}
                  record={record}
                  hideCreatedAtAndStatus={hideCreatedAtAndStatus}
                />
              ),
            )
        }
      </div>
    </div>
  );
}

MultiTableCard.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

MultiTableCard.propTypes = {
  moduleName: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default MultiTableCard;
