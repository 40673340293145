import React, { useState } from 'react';
import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';
import { ToolTip } from 'clm-components';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import StatusTag from '../../../Common/Tags/StatusTag';

import { ReactComponent as DotIcon } from '../../../../assests/icons/dot.svg';
import { ReactComponent as FlagIcon } from '../../../../assests/icons/flag.svg';
import { getOrdinalSuffix } from '../../RecordUtils';
import ChevronIcon from '../../../Common/Icons/ChevronIcon';
import getComponentForCardType, { formatRecordsForCardTypes } from '../utils';

function TimelineViewItem({
  name,
  time,
  isSuccess,
  attempt,
  status,
  createdAt,
  flag,
  end,
  moduleId,
  ...restParams
}) {
  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const endpointModule = get(restParams, 'endpointModule', {});
  const extractedData = get(restParams, 'extractedData');
  const extractedReqData = get(restParams, 'extractedReqData');
  const cardType = get(endpointModule, 'cardType', 'default');
  const [expand, setExpand] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setExpand(!expand);
  };

  const displayTooltip = () => {
    const tooltip = document.getElementById(`timeline_tooltip_${name}_${attempt}`);
    tooltip?.classList?.add('active');
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById(`timeline_tooltip_${name}_${attempt}`);
    tooltip?.classList?.remove('active');
  };

  return (
    <TimelineItem data-date={createdAt} className="application_details__timeline_view__item__container">
      <TimelineContent className="application_details__timeline_view__timeline_content__time">
        <div className="application_details__timeline_view__timeline_content__time_container">
          <p>{time}</p>
        </div>
      </TimelineContent>
      <TimelineSeparator className="application_details__timeline_view__separator">
        <TimelineDot />
        {end ? null : <TimelineConnector className="application_details__timeline_view__connector" />}
      </TimelineSeparator>
      <TimelineContent onClick={handleClick} className="application_details__timeline_view__timeline_content__module">
        <div className="application_details__timeline_view__timeline_content__module_container">
          <div className={`application_details__timeline_view__timeline_content__module_container__overview ${expand ? 'expanded' : ''}`}>
            <p>
              <ChevronIcon classname={expand ? 'expanded' : ''} />
              {name}
              <DotIcon className="application_details__timeline_view__timeline_content__module_dot_icon" />
              <span>
                {getOrdinalSuffix(attempt)}
                {' '}
                attempt
              </span>
              {!isSuccess && flag && (
              <>
                <ToolTip title={flag} placement="top">
                  <FlagIcon onMouseEnter={displayTooltip} onMouseLeave={hideTooltip} className="application_details__timeline_view__timeline_content__module_flag_icon" />
                </ToolTip>
              </>
              )}
            </p>
            <StatusTag status={status} className="application_details__timeline_view__timeline_content__module_status" />
          </div>
          {expand && (
          <div className="application_details__timeline_view__timeline_content__module_container__details">
            {React.cloneElement(getComponentForCardType(cardType), {
              moduleId,
              moduleName: name,
              subType: get(restParams, 'subType'),
              records: formatRecordsForCardTypes(
                cardType,
                [{
                  ...restParams,
                  endpointModule,
                  extractedData,
                  extractedReqData,
                  createdAt,
                  isSuccess,
                  status,
                  moduleId,
                }],
              ),
              transactionData,
              hideHeader: true,
              hideTabs: true,
              hideCreatedAtAndStatus: true,
            })}
          </div>
          )}
        </div>
      </TimelineContent>
    </TimelineItem>
  );
}

TimelineViewItem.defaultProps = {
  flag: '',
  end: false,
  moduleId: '',
  restParams: {},
};

TimelineViewItem.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  attempt: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  isSuccess: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  flag: PropTypes.string,
  end: PropTypes.bool,
  moduleId: PropTypes.string,
  restParams: PropTypes.object,
};

export default TimelineViewItem;
