import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AMLCheckPanel from '../Panels/AMLCheckPanel';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';

const AMLChecks = ({
  moduleName,
  subType,
  records,
  hideHeader,
  hideTabs,
  hideCreatedAtAndStatus,
}) => {
  const [amlDetailArray, setAmlDetailArrayFront] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setAmlDetailArrayFront(records);
  }, [records]);

  return (
    <div id="details__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType={subType} />}
      {!hideTabs && (
      <CustomTabs
        id="details__body_tabs"
        tabIndex={value}
        setTabIndex={setValue}
        tabArray={amlDetailArray}
      />
      )}
      {amlDetailArray.map((item, index) => (
        <AMLCheckPanel
          key={item.id}
          value={value}
          index={index}
          item={item}
          createdAt={item.createdAt}
          status={item.apiStatus}
          hideCreatedAtAndStatus={hideCreatedAtAndStatus}
        />
      ))}
    </div>
  );
};

AMLChecks.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

AMLChecks.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default AMLChecks;
