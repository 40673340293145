import React, { useState } from 'react';

import PropTypes from 'prop-types';

import smallArrow from '../../../assests/icons/smallArrow.svg';
import './Accordion.scss';

function Accordion({
  title, content, show, fadeIn,
}) {
  const [isOpen, setIsOpen] = useState(show);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={fadeIn ? 'fade-in accordion__container' : 'accordion__container'}>
      <button
        type="button"
        className={`accordion__button ${isOpen ? 'button_show' : ''}`}
        onClick={toggleAccordion}
        aria-expanded={isOpen}
      >
        <span>{title}</span>
        <img src={smallArrow} alt="arrow" className={isOpen ? 'open' : ''} />
      </button>
      <div className={`accordion__content_container ${isOpen ? 'show' : ''}`}>
        <div className="accordion__inner_content">
          <div>
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}

Accordion.defaultProps = {
  fadeIn: PropTypes.bool,
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  fadeIn: PropTypes.bool,
};

export default Accordion;
