import React from 'react';
import PropTypes from 'prop-types';
import './Shimmer.scss';

function Shimmer({
  width, height, padding, borderRadius, backgroundColor, border, id,
}) {
  return (
    <div
      id={id}
      className="shimmer"
      style={{
        width,
        height,
        padding,
        borderRadius,
        background: `linear-gradient(90deg, ${backgroundColor} 25%, ${backgroundColor} 50%, ${backgroundColor} 75%)`,
        backgroundSize: '200% 100%',
        animation: 'shimmer 1.5s infinite linear',
        border: `${border}`,
      }}
    />
  );
}

Shimmer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  id: PropTypes.string,
};

Shimmer.defaultProps = {
  width: '36px',
  height: '36px',
  padding: '6px',
  borderRadius: '6px',
  backgroundColor: '#f0f0f0',
  border: 'none',
  id: '',
};

export default Shimmer;
