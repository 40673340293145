import React from 'react';
import PropTypes from 'prop-types';
import { get, isObject } from 'lodash';

function TableCell({ value, className }) {
  if (isObject(value)) {
    const { type } = value;
    if (type === 'link') {
      const { link, label } = value;
      return (
        <a onClick={(event) => event.stopPropagation()} className={className} href={link} target="_blank" rel="noreferrer">{label}</a>
      );
    }
    return get(value, 'label', '');
  }
  return (
    <div className={className}>{value}</div>
  );
}

TableCell.defaultProps = {
  value: '',
  className: '',
};

TableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  className: PropTypes.string,
};

export default TableCell;
