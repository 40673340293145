import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatDateForApplicationTable } from '../../../../../../../utils/dateFormats';

import Menu from './Menu';
import StatusTag from '../../../../../../Common/Tags/StatusTag';
import HeaderButton from './HeaderButton';
import { ReactComponent as DownloadIcon } from '../../../../../../../assests/icons/downloadArrowDown.svg';
import { findStatus } from '../../../../../RecordUtils';

function Header({
  headerConfig, availableData,
}) {
  const { createdAt } = get(availableData, 'recordData', {});

  const { downloadButton, menu = {} } = headerConfig;
  const { items: menuItems = [] } = menu;

  return (
    <div className="card_type_five__content__header">
      <div className="card_type_five__content__header__status">
        <p>
          Attempted at:
          <span>{formatDateForApplicationTable(createdAt)}</span>
        </p>
        <p>
          Status:
          <StatusTag status={findStatus({ extractedData: availableData, statusCode: get(availableData, ['recordData', 'statusCode']) })} />
        </p>
      </div>
      <div className="card_type_five__content__header__actions">
        <HeaderButton
          icon={DownloadIcon}
          buttonConfig={downloadButton}
          availableData={availableData}
        />
        <Menu items={menuItems} availableData={availableData} />
      </div>
    </div>
  );
}

Header.defaultProps = {
  headerConfig: {},
};

Header.propTypes = {
  headerConfig: PropTypes.object,
  availableData: PropTypes.object.isRequired,
};

export default Header;
