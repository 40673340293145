import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import WelcomeCard from '../Cards/WelcomeCard';
import RedirectCards from '../Cards/RedirectCards';

function HeaderSection(
  {
    redirectToVKYC,
    loading,
    fadeIn,
  },
) {
  const redirectCardData = useSelector((state) => get(state, 'user.homePageData.redirectCards', []));
  const welcomeEmoji = useSelector((state) => get(state, 'user.homePageData.welcomeEmoji', ''));
  return (
    <>
      <WelcomeCard onRedirect={redirectToVKYC} welcomeEmoji={welcomeEmoji} />
      <RedirectCards data={redirectCardData} loading={loading} fadeIn={fadeIn} />
    </>
  );
}

HeaderSection.propTypes = {
  redirectToVKYC: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fadeIn: PropTypes.bool.isRequired,
};

export default HeaderSection;
