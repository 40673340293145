import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { first, get } from 'lodash';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import ImageViewerOpenWrapper from '../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import PDFViewerOpenWrapper from '../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import MediaWrapper from '../MediaWrapper/MediaWrapper';
import seperatePDFsAndImages from '../MediaWrapper/utils';
import { MissingRecordComponent } from '../Cards/Common';

const defaultImageType = 'image';
function SelfieverificationPanel({
  value, index, item, createdAt, status, imageTypeToShow, hideCreatedAtAndStatus,
}) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);
  const isMissing = item?.isMissing || false;

  const imageToShow = item.fileUrls.find(
    ({ type }) => type === imageTypeToShow,
  ) || get(item, 'fileUrls[0]', null);

  const imageSrc = get(imageToShow, 'url')
  || (get(item, 'base64Image[0]')
    ? `data:image/jpeg;base64,${item.base64Image[0]}`
    : null);

  const { images: viewerImages, pdfs } = seperatePDFsAndImages([
    { src: imageSrc, label: item.inputTitle },
  ]);

  const handleImageClick = () => {
    setIsViewerOpen(true);
  };

  const handlePDFClick = () => {
    setIsPDFViewerOpen(true);
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false);
  };

  const handlePDFViewerClose = () => {
    setIsPDFViewerOpen(false);
  };

  return (
    <>
      <ImageViewerOpenWrapper
        images={viewerImages}
        open={isViewerOpen}
        closeViewerHandler={handleViewerClose}
      />
      <PDFViewerOpenWrapper
        open={isPDFViewerOpen}
        pdf={first(pdfs) || {}}
        closeViewerHandler={handlePDFViewerClose}
      />
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {!isMissing ? (
          <div id="details__tab__container">
            {
              !hideCreatedAtAndStatus
              && <CreatedAtAndStatus status={status} createdAt={createdAt} />
            }
            <div id="details__tab__details__container">
              <div id="details__tab__details__image__container">
                <h1>{item.inputTitle}</h1>
                {imageSrc ? (
                  <MediaWrapper
                    id="details__tab__details__image"
                    src={imageSrc}
                    onImageClick={handleImageClick}
                    onPDFClick={handlePDFClick}
                  />
                ) : <div id="details__tab__details__image" />}
              </div>
              <div id="details__tab__details__body">
                <h1>{item.outputTitle}</h1>
                {item.extractedData.map((ele, idx) => (
                  <div key={ele.key} id="table__body">
                    <h2>{item.secTitle[idx]}</h2>
                    <div id="details__tab__details__body__table_container">
                      <table>
                        {ele.map((elem) => (
                          <tr key={elem.key}>
                            {elem.key === 'Error' ? <td className="err">{elem.value}</td> : ''}
                            <td className={`left ${elem.flag}`}>{elem.key === 'Error' ? '' : elem.key}</td>
                            <td data-hj-suppress className={`right ${elem.flag}`}>
                              {elem.key !== 'Error' ? (
                                <>
                                  {elem.value}
                                  <span className="conf">
                                    low conf.
                                  </span>
                                </>
                              )
                                : ''}
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <MissingRecordComponent />
        )}
      </div>

    </>
  );
}

SelfieverificationPanel.defaultProps = {
  status: '',
  createdAt: '',
  imageTypeToShow: defaultImageType,
  hideCreatedAtAndStatus: false,
};

SelfieverificationPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  imageTypeToShow: PropTypes.string,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default SelfieverificationPanel;
