import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, has } from 'lodash';
import IdTabPanel from '../Panels/IdTabPanel';
import setImageTypeBasedOnConfig from './utils';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';

const IdVerification = ({
  moduleName, subType, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) => {
  const [idDetailArrayFront, setIdDetailArrayFront] = useState([]);
  const [idDetailArrayBack, setIdDetailArrayBack] = useState([]);
  const [cardTitles, setCardTitles] = useState({
    front: '',
    back: '',
  });

  const [frontIndex, setFrontIndex] = useState(0);
  const [backIndex, setBackIndex] = useState(0);

  useEffect(() => {
    const detailsFront = records.filter((elem) => get(elem, 'endpointModule.conditions[0].expectedDocumentSide') === 'front' || !has(elem, 'endpointModule.conditions'));
    const detailsBack = records.filter((elem) => has(elem, 'endpointModule.conditions') && get(elem, 'endpointModule.conditions[0].expectedDocumentSide') !== 'front');
    setIdDetailArrayFront(setImageTypeBasedOnConfig(detailsFront));
    setIdDetailArrayBack(setImageTypeBasedOnConfig(detailsBack));
    // Handle cases where moduleName was fetched from endpointmodule
    // ( having front/back in name itself ) and not workflow
    if (/front|back|side/i.test(moduleName)) {
      setCardTitles({
        front: get(detailsFront, '[0].endpointModule.moduleName', moduleName),
        back: get(detailsBack, '[0].endpointModule.moduleName', moduleName),
      });
    } else {
      setCardTitles({
        front: `${moduleName} - Front Side`,
        back: `${moduleName} - Back Side`,
      });
    }
    setFrontIndex(0);
    setBackIndex(0);
  }, [records]);

  return (
    <>
      <div id="details__container">
        {idDetailArrayFront && idDetailArrayFront.length > 0
          ? (
            <div id="details__container__section">
              {!hideHeader && <CommonHeader moduleName={cardTitles.front} subType={subType} />}
              {!hideTabs && (
              <CustomTabs
                id="details__body_tabs"
                tabIndex={frontIndex}
                setTabIndex={setFrontIndex}
                tabArray={idDetailArrayFront}
              />
              )}
              {idDetailArrayFront.map((item, index) => (
                <IdTabPanel
                  key={item.id}
                  value={frontIndex}
                  index={index}
                  item={item}
                  createdAt={item.createdAt}
                  status={item.apiStatus}
                  imageTypeToShow={item.imageTypeToShow}
                  hideCreatedAtAndStatus={hideCreatedAtAndStatus}
                />
              ))}
            </div>
          )
          : ''}
        {idDetailArrayBack && idDetailArrayBack.length > 0 ? (
          <div id="details__container__section">
            <CommonHeader moduleName={cardTitles.back} subType={subType} />
            <CustomTabs
              id="details__body_tabs"
              tabIndex={backIndex}
              setTabIndex={setBackIndex}
              tabArray={idDetailArrayBack}
            />
            {idDetailArrayBack.map((item, index) => (
              <IdTabPanel
                key={item.id}
                value={backIndex}
                index={index}
                item={item}
                createdAt={item.createdAt}
                status={item.apiStatus}
                imageTypeToShow={item.imageTypeToShow}
                hideCreatedAtAndStatus={hideCreatedAtAndStatus}
              />
            ))}
          </div>
        ) : '' }
      </div>
    </>
  );
};

IdVerification.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

IdVerification.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default IdVerification;
