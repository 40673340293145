import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { get, startCase, findIndex } from 'lodash';
import { getActiveURL } from '../../RecordUtils';
import CreatedAtAndStatus from '../../CreatedAtAndStatus';
import PDFViewerOpenWrapper from '../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import MediaWrapper from '../MediaWrapper/MediaWrapper';

import { isValidURL } from '../../../../utils/helpers';
import seperatePDFsAndImages from '../MediaWrapper/utils';
import ImageViewerOpenWrapper from '../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import { MissingRecordComponent } from '../Cards/Common';

function MultiTablePanel({
  record, index, currentTabIndex, hideCreatedAtAndStatus,
}) {
  const [tablesConfig, setTablesConfig] = useState({ tableDataConfig: {}, tableTitlePath: '' });
  const [tables, setTables] = useState([]);
  const [showTableLink, setShowTableLink] = useState('no');
  const [pdfs, setPdfs] = useState([]);
  const [images, setImages] = useState([]);
  const [pdfIndex, setPDFIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const isMissing = record?.isMissing || false;

  const onClosePDFViewer = () => {
    setShowPDFViewer(false);
  };

  const onPDFClick = (idx) => {
    setPDFIndex(idx);
    setShowPDFViewer(true);
  };

  const onCloseImageViewer = () => {
    setShowImageViewer(false);
  };

  const onImageClick = (idx) => {
    setImageIndex(idx);
    setShowImageViewer(true);
  };

  const getTableConfigAndData = () => {
    const {
      endpointModule: {
        tableDataConfig, tableTitlePath, tablesArrayPath, showLink,
      }, extractedData,
    } = record;
    setTablesConfig({
      tableDataConfig,
      tableTitlePath,
    });
    setTables(get(extractedData, tablesArrayPath));
    setShowTableLink(showLink);
  };

  const checkInvalidKey = (key) => (tablesConfig?.tableDataConfig?.blacklistedKeys?.some(
    (blacklistedKey) => blacklistedKey === key,
  ));

  const getLabel = (key) => (tablesConfig?.tableDataConfig?.labels
    && tablesConfig?.tableDataConfig?.labels[key]) || startCase(key);

  const returnMedia = (item) => ((!showTableLink || showTableLink === 'no') && !isMissing ? (
    <>
      <MediaWrapper
        id="summary__image_carousal"
        src={getActiveURL(
          record.fileUrls,
          get(item, tablesConfig?.tableDataConfig?.titleUrlPath),
        )}
        onPDFClick={() => onPDFClick(findIndex(pdfs, (ele) => ele.src === getActiveURL(
          record.fileUrls,
          get(item, tablesConfig?.tableDataConfig?.titleUrlPath),
        )))}
        onImageClick={() => onImageClick(findIndex(images, (ele) => ele.src === getActiveURL(
          record.fileUrls,
          get(item, tablesConfig?.tableDataConfig?.titleUrlPath),
        )))}
      />
      <PDFViewerOpenWrapper
        open={showPDFViewer}
        pdf={get(pdfs, pdfIndex, {})}
        closeViewerHandler={onClosePDFViewer}
      />
      <ImageViewerOpenWrapper
        open={showImageViewer}
        images={[get(images, imageIndex, {})]}
        closeViewerHandler={onCloseImageViewer}
      />
    </>
  ) : (
    <a
      data-hj-suppress
      href={getActiveURL(
        record.fileUrls,
        get(item, tablesConfig?.tableDataConfig?.titleUrlPath),
      )}
    >
      Download Link
    </a>
  ));

  useEffect(() => {
    getTableConfigAndData();
  }, [record]);

  useEffect(() => {
    if (tables && Array.isArray(tables)) {
      const mediaHolder = [];
      tables.forEach((item) => {
        mediaHolder.push({
          label: get(item, tablesConfig?.tableTitlePath),
          src: getActiveURL(
            record.fileUrls,
            get(item, tablesConfig?.tableDataConfig?.titleUrlPath),
          ),
        });
      });
      const { images: viewerImages, pdfs: viewerPDF } = seperatePDFsAndImages(mediaHolder);
      setPdfs(viewerPDF);
      setImages(viewerImages);
    }
  }, [tables]);

  return (
    <div id="multi_table_panel__container" hidden={index !== currentTabIndex}>
      <>
        {isMissing ? <MissingRecordComponent /> : (
          <>
            {
              !hideCreatedAtAndStatus
              && <CreatedAtAndStatus createdAt={record?.createdAt} status={record?.status} />
            }
            { tables && Array.isArray(tables) && tables.map((item) => {
              const data = get(item, tablesConfig?.tableDataConfig?.path);
              return (
                <div id="multi_table_panel__table__container" key={get(item, tablesConfig?.tableTitlePath)}>
                  <table>
                    <tr>
                      <td className="heading"><p>{get(item, tablesConfig?.tableTitlePath)}</p></td>
                      <td>
                        {get(item, tablesConfig?.tableDataConfig?.titleUrlPath)
                          ? (returnMedia(item))
                          : <></>}
                      </td>
                    </tr>
                    {data && Object.keys(data)?.length > 0 ? Object.keys(data)?.map((key) => {
                      if (!checkInvalidKey(key)) {
                        return (
                          <tr key={key}>
                            <td className="heading"><p>{getLabel(key)}</p></td>
                            <td>
                              {
                        isValidURL(data[key])
                          ? (
                            <a
                              data-hj-suppress
                              href={
                            getActiveURL(record.fileUrls, data[key])
                          }
                            >
                              Click here to download
                            </a>
                          )
                          : <p>{data[key]}</p>
                        }

                            </td>
                          </tr>
                        );
                      }
                      return <></>;
                    }) : <p>No data found</p>}
                  </table>
                </div>
              );
            }) }
          </>
        )}
      </>
    </div>
  );
}

MultiTablePanel.defaultProps = {
  hideCreatedAtAndStatus: false,
};

MultiTablePanel.propTypes = {
  record: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  currentTabIndex: PropTypes.number.isRequired,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default MultiTablePanel;
