import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import HitsContent from './HitsContent';
import Content from './Content';

function ContentWrapper({
  contentConfig, hitsConfig, moduleId, hideCreatedAtAndStatus,
}) {
  if (!isEmpty(hitsConfig)) {
    return (
      <HitsContent
        contentConfig={contentConfig}
        hitsConfig={hitsConfig}
        moduleId={moduleId}
        hideCreatedAtAndStatus={hideCreatedAtAndStatus}
      />
    );
  }

  return (
    <Content
      contentConfig={contentConfig}
      moduleId={moduleId}
      hideCreatedAtAndStatus={hideCreatedAtAndStatus}
    />
  );
}

ContentWrapper.defaultProps = {
  hideCreatedAtAndStatus: false,
};

ContentWrapper.propTypes = {
  contentConfig: PropTypes.object.isRequired,
  hitsConfig: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default ContentWrapper;
