import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import DemoModal from '../DemoModal';
import Shimmer from '../../../Common/animations/shimmer/ShimmerEffect';
import RedirectCardContent from './RedirectCardContent';

function RedirectCards({ data, loading, fadeIn }) {
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [hoverStates, setHoverStates] = useState({});
  const docToken = useSelector((state) => get(state, 'onboard.docToken', ''));

  const closeDemoModal = () => {
    setOpenDemoModal(false);
  };

  const getClickHandler = (redirectLink, id) => {
    if (id === 'redirectCard_workflows') {
      setOpenDemoModal(true);
    }
    if (redirectLink) {
      const docPortalUrl = process.env.REACT_APP_DOCUMENTATION_PORTAL_URL;

      if (redirectLink.includes(docPortalUrl)) {
        const updatedLink = `${redirectLink}/?jwt=${docToken}`;
        window.open(updatedLink, '_blank');
      } else {
        window.location.href = redirectLink;
      }
    }
  };

  const handleMouseEnter = (key) => {
    setHoverStates((prevStates) => ({ ...prevStates, [key]: true }));
  };

  const handleMouseLeave = (key) => {
    setHoverStates((prevStates) => ({ ...prevStates, [key]: false }));
  };

  return (
    <>
      <div id="home_page__redirectCards_container">
        {data.map(({
          key, id, title, content, customStyles, redirectLink, iconUrl, shimmerColor,
        }) => (
          <div
            key={key}
            id="home_page__redirectCards_container__card"
            onClick={() => getClickHandler(redirectLink, id)}
            aria-hidden
            onMouseEnter={() => handleMouseEnter(title)}
            onMouseLeave={() => handleMouseLeave(title)}
          >
            <div id="icon_section">
              {loading ? (
                <Shimmer
                  width="36px"
                  height="36px"
                  padding="6px"
                  borderRadius="6px"
                  backgroundColor={shimmerColor}
                />
              ) : (
                <div id="icon_container" style={{ background: get(customStyles, 'background', shimmerColor) }}>
                  <img src={iconUrl} alt="icon" className={fadeIn ? 'fade-in' : ''} />
                </div>
              )}
            </div>
            {!loading && RedirectCardContent({
              title,
              content,
              redirectLink,
              customStyles,
              isHovered: hoverStates[title] || false,
              fadeIn,
            })}
          </div>
        ))}
      </div>
      <DemoModal open={openDemoModal} closePopupWindow={closeDemoModal} />
    </>
  );
}

RedirectCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      className: PropTypes.string.isRequired,
      redirectLink: PropTypes.string,
      iconUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  fadeIn: PropTypes.bool.isRequired,
};

export default RedirectCards;
