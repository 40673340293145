// TODO: code refactoring of this method

const formatData = (paramDetails) => {
  const detailsArray = [];
  const detailsReqArray = [];
  const details = [...paramDetails];
  details?.forEach((element) => {
    if (!element.endpointModule) {
      detailsArray.push(element);
      return;
    }
    const isDbCheckReq = element.endpointModule.cardType === 'type3';
    const secTitleArray = [];
    const secDetails = element.endpointModule?.output?.sections;
    const conditionsDetails = element.endpointModule?.conditions;
    const inputDetails = element.endpointModule?.input;
    const outputTitle = element.endpointModule?.output?.title;
    const {
      inputTitle, inputTitle1, image3, image4,
    } = element?.endpointModule?.input || {};
    const obj = {};
    const updatedData = [];
    const base64Image = [];
    const reqObj = {};
    const updateReqData = [];
    const updateIdReqData = [];
    const apiStatus = element.status;
    const { createdAt } = element;
    let missingRecord = {};

    if (element.isMissing) {
      missingRecord = {
        ...element,
        isMissing: true,
        moduleId: element.moduleId,
        extractedReqData: [],
        extractedData: [],
        outputTitle,
        inputTitle,
        inputTitle1,
        secTitle: secTitleArray,
        endpointModule: element.endpointModule,
        apiStatus,
        createdAt: element.createdAt,
        isSuccess: element.isSuccess,
      };
    }
    // this is to get the country name and side of document
    // from request of Iverification apis
    if (element.extractedReqData && !isDbCheckReq && !element.isMissing) {
      const updateIdData = [];
      const { fields } = inputDetails || {};
      element.extractedReqData?.forEach((idData) => {
        const reqIdObj = {};
        idData?.forEach((d) => {
          if (conditionsDetails && conditionsDetails.length > 0) {
            conditionsDetails?.forEach((condition) => {
              if (condition && condition.length > 0) {
                Object.keys(condition)?.forEach((condKey) => {
                  if (d.key === condKey) reqIdObj.side = d.value;
                });
              }
            });
          }
          if (fields && fields.length > 0) {
            fields?.forEach((field) => {
              if (d.key === field.key) {
                reqIdObj[`${d.key}`] = d.value;
              }
            });
          }
          if (image3 === d.key) {
            base64Image.push(d.value);
          }
          if (image4 === d.key) {
            base64Image.push(d.value);
          }
        });
        updateIdData.push(reqIdObj);
      });
      obj.base64Image = base64Image;
      updateIdReqData.push(updateIdData);
      reqObj.extractedReqData = updateIdReqData;
      detailsReqArray.push(reqObj);
    }

    // for all db check requests format the req according to config
    if ((isDbCheckReq || element.endpointModule.cardType === 'type5') && !element.isMissing) {
      const updatedDataDb = [];
      const { fields } = inputDetails;
      reqObj.fileUrls = element.fileUrls;
      fields?.forEach((e) => {
        element.extractedReqData?.forEach((el) => {
          el?.forEach((data) => {
            const newData = { ...data };
            let isDataEmpty = false;
            if (data.key === e.key) {
              newData.key = e.name;
              if (data.value === '' && e.persist && e.persist === 'yes') {
                newData.value = '--';
              } else if (data.value === '')isDataEmpty = true;
              if (e.flags && e.flags.length > 0) {
                e.flags?.forEach((flag) => {
                  if (data.confidence && flag.value === data.confidence) {
                    newData.flag = flag.type;
                  }
                  if (data.value && flag.value === data.value) {
                    newData.flag = flag.type;
                  }
                });
              }
              if (!newData.flag) newData.flag = 'normal';
              if (!isDataEmpty) updatedDataDb.push(newData);
            }
          });
        });
      });
      // set array here
      updateReqData.push(updatedDataDb);
      reqObj.extractedReqData = updateReqData;
      detailsReqArray.push(reqObj);
    }
    if (element.endpointModule.cardType !== 'type5' && !element.isMissing) {
      secDetails?.forEach((elem) => {
        const updatedData1 = [];
        const { fields } = elem;
        obj.fileUrls = element.fileUrls;
        fields?.forEach((e) => {
          element.extractedData?.forEach((el) => {
            el?.forEach((data) => {
              const newData = { ...data };
              let isDataEmpty = false;
              if (data.key === e.key) {
                newData.key = e.name;
                if (data.value === '' && e.persist && e.persist === 'yes') {
                  newData.value = '--';
                } else if (data.value === '') {
                  isDataEmpty = true;
                }
                if (e.flags && e.flags.length > 0) {
                  e.flags?.forEach((flag) => {
                    if (data.confidence && flag.value === data.confidence) {
                      newData.flag = flag.type;
                    }
                    if (data.value && flag.value === data.value) {
                      newData.flag = flag.type;
                    }
                  });
                }
                if (!newData.flag) newData.flag = 'normal';
                if (!isDataEmpty && element.endpointModule.cardType !== 'type5') {
                  updatedData1.push(newData);
                }
              }
            });
          });
          // set array here
        });
        secTitleArray.push(elem.sectionTitle);
        if (updatedData1.length > 0) {
          updatedData.push(updatedData1);
        }
      });
      obj.extractedData = updatedData;
      obj.extractedReqData = reqObj.extractedReqData;
    } else {
      obj.extractedData = element.extractedData;
      obj.extractedReqData = reqObj.extractedReqData;
    }
    obj.outputTitle = outputTitle;
    obj.inputTitle = inputTitle;
    obj.inputTitle1 = inputTitle1;
    obj.secTitle = secTitleArray;
    obj.endpointModule = element.endpointModule;
    obj.apiStatus = apiStatus;
    obj.createdAt = createdAt;
    obj.moduleId = element.moduleId;
    obj.isSuccess = element.isSuccess;

    if (element.isMissing) {
      detailsArray.push(missingRecord);
      return;
    }

    detailsArray.push(obj);
  });
  return detailsArray;
};

export default formatData;
