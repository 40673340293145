import axios from 'axios';
import { format } from 'date-fns';

const getWorkflows = async (appId, getDbWorkflow = false, startTime = null, endTime = null) => {
  let url = `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow`;
  if (getDbWorkflow) {
    url += '?getDbWorkflow=true';
    if (startTime) {
      const startTimeFormatted = format(new Date(startTime), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      url += `&startTime=${startTimeFormatted}`;
    }
    if (endTime) {
      const endTimeFormatted = format(new Date(endTime), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      url += `&endTime=${endTimeFormatted}`;
    }

    // url += '&getAppIdWorkflows=yes';
  } else {
    // url += '?getAppIdWorkflows=yes';
  }

  const dataRes = await axios({
    method: 'GET',
    url,
    headers: { appId },
  });
  const workflowMap = dataRes.data.result.workflows;
  const workflowList = Object.keys(workflowMap).map((key) => ({
    ...workflowMap[key],
    id: key,
  }));
  return workflowList;
};

export default getWorkflows;
