/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initCredState = {
  current: {
    clientId: '',
    useCase: '',
    appId: '',
    type: '',
  },
  credMapping: {},
};

const initHomePageData = {
  redirectCards: [],
  videoCards: [],
  FAQCards: [],
  welcomeEmoji: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    appIds: {},
    permissions: null,
    role: '',
    email: '',
    auth: false,
    clientId: '',
    workflowList: [],
    authorizedProducts: [],
    userLocation: {},
    workflow: {},
    workflowIdListWithDbData: [],
    credState: initCredState,
    homePageData: initHomePageData,
  },
  reducers: {
    updateUserInfo: (state, action) => {
      const {
        permissions, role, email, appIds, clientId, isNdaAgreed, shouldShowNDA, authorizedProducts,
      } = action.payload;
      state.permissions = permissions;
      state.role = role;
      state.email = email;
      state.appIds = appIds;
      state.clientId = clientId;
      state.isNdaAgreed = isNdaAgreed;
      state.shouldShowNDA = shouldShowNDA;
      state.authorizedProducts = authorizedProducts;
    },

    updateCredMapping: (state, action) => {
      state.credState.credMapping = action.payload;
    },

    updateCredState: (state, action) => {
      const {
        clientId, useCase, appId, type,
      } = action.payload;
      if (clientId) {
        state.credState.current.clientId = clientId;
      }
      if (useCase) {
        state.credState.current.useCase = useCase;
      }
      if (appId) {
        state.credState.current.appId = appId;
      }
      if (type) {
        state.credState.current.type = type;
      }
    },

    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateIsAuthenticated: (state, action) => {
      state.auth = action.payload;
    },
    updateWorkflowList: (state, action) => {
      state.workflowList = action.payload;
    },

    updateWithDbWorkflowList: (state, action) => {
      state.workflowIdListWithDbData = action.payload;
    },

    updateWorkflow: (state, action) => {
      const { workflowModules, appId, workflowId } = action.payload;
      state.workflow[`${appId}_${workflowId}`] = workflowModules;
    },
    updateUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    updateHomePageData: (state, action) => {
      state.homePageData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserInfo, updateEmail, updateUserLocation, updateWorkflow,
  updateIsAuthenticated, updateWorkflowList, updateWithDbWorkflowList,
  updateCredMapping,
  updateCredState,
  updateHomePageData,
} = userSlice.actions;
export default userSlice.reducer;
