import React from 'react';

import PropTypes from 'prop-types';

import './Iframe.scss';

function IframeContainer({ src, title, styles }) {
  return (
    <iframe
      id="dashboard__iframe__container"
      src={src}
      allow="camera; microphone; clipboard-read; clipboard-write"
      title={title}
      style={{ ...styles }}
    />
  );
}

IframeContainer.defaultProps = {
  styles: {},
};

IframeContainer.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

export default IframeContainer;
