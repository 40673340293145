import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CarrotDownIcon } from '../../../../../assests/icons/carrotDown.svg';

function Collapse({ title, maxHeight, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [children]);

  return (
    <div className="card_collapse__container">
      <button
        type="button"
        className={`card_collapse__button ${isOpen && 'active'}`}
        onClick={toggleCollapse}
      >
        {title}
        <CarrotDownIcon />
      </button>
      {isOpen && <div className="card_collapse__children" style={{ maxHeight }}>{children}</div>}
    </div>
  );
}

Collapse.defaultProps = {
  maxHeight: null,
  title: 'More Information',
};

Collapse.propTypes = {
  maxHeight: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Collapse;
