const navMapping = {
  '/home': {
    navKey: 'home',
  },
  '/applications': {
    navKey: 'applications',
  },
  '/review': {
    navKey: 'applications',
  },
  '/credentials': {
    navKey: 'devHub',
    subNavKey: 'credentials',
  },
  '/usage': {
    navKey: 'account',
    subNavKey: 'usage',
  },
  '/pricing': {
    navKey: 'account',
    subNavKey: 'pricingInfo',
  },
  '/business-metrics': {
    navKey: 'analytics',
    subNavKey: 'businessMetrics',
  },
  '/stepwise-analytics': {
    navKey: 'analytics',
    subNavKey: 'stepwiseAnalytics',
  },
  '/custom-report': {
    navKey: 'analytics',
    subNavKey: 'customReport',
  },
  '/users': {
    navKey: 'account',
    subNavKey: 'users',
  },
  '/workflows': {
    navKey: 'workflows',
  },
};

export default navMapping;
