import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import Accordion from '../../../Common/Accordion/Accordion';
import HomeFaq from '../../../../assests/images/HomeFaq.svg';
import Shimmer from '../../../Common/animations/shimmer/ShimmerEffect';

function FAQSection({ fadeIn, loading }) {
  const accordionData = useSelector((state) => get(state, 'user.homePageData.FAQCards', []));
  const docToken = useSelector((state) => get(state, 'onboard.docToken', ''));
  const DOCUMENTATION_PORTAL_URL = `${process.env.REACT_APP_DOCUMENTATION_PORTAL_URL}/other-resources/faq/?jwt=${docToken}`;

  const checkAndSetDocTokenIfDocumentationUrl = (url) => {
    if (url.includes(process.env.REACT_APP_DOCUMENTATION_PORTAL_URL)) {
      return `${url}/?jwt=${docToken}`;
    }
    return url;
  };

  const parseLinks = (value) => {
    const regex = /\{([^\\}]+)\}\[([^\]]+)\]\{([^\\}]+)\}/g;

    const parts = [];
    let lastIndex = 0;

    value.replace(regex, (match, linkText, url, _text, offset) => {
      if (offset > lastIndex) {
        parts.push(value.slice(lastIndex, offset));
      }

      const jwtSignedUrl = checkAndSetDocTokenIfDocumentationUrl(url);

      parts.push(
        <a href={jwtSignedUrl} key={jwtSignedUrl} target="_blank" rel="noreferrer">
          {linkText}
        </a>,
      );

      lastIndex = offset + match.length;

      return match;
    });

    if (lastIndex < value.length) {
      parts.push(value.slice(lastIndex));
    }

    return parts;
  };

  const generateAccordionContent = (accordionContent) => {
    if (Array.isArray(accordionContent)) {
      return (
        accordionContent.map((item) => (
          <p key={item}>
            {item.startsWith('-')
              ? `\u00A0\u00A0${parseLinks(item)}`
              : parseLinks(item)}
          </p>
        ))
      );
    }
    return <p>{parseLinks(accordionContent)}</p>;
  };

  return (
    <div id="section_three_faq_container">
      <div id="faq_top_container">
        <span>Your top questions, answered.</span>
        <div id="faq_top_container__link">
          <a
            href={DOCUMENTATION_PORTAL_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            View More
          </a>
        </div>
      </div>
      <div id="section_three_faq_content_container">
        <div id="faq_left_container">
          <div id="faq_left_bottom_container">
            <img src={HomeFaq} alt="FAQ Illustration" />
          </div>
        </div>
        <div id="faq_right_container">
          {accordionData.map((accordion) => (loading ? (
            <Shimmer
              key={accordion.title}
              id="faq_right_container__shimmer"
              width="100%"
              height="52px"
              padding="16px"
              borderRadius="8px"
              backgroundColor="rgb(255, 255, 255)"
              border="0.5px solid rgba(5, 5, 82, 0.15)"
            />
          ) : (
            <>
              <Accordion
                key={accordion.title}
                title={accordion.title}
                content={generateAccordionContent(accordion.content)}
                show={accordion.show ?? false}
                fadeIn={fadeIn}
              />
            </>
          )))}
        </div>
      </div>
    </div>
  );
}

FAQSection.propTypes = {
  fadeIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FAQSection;
