import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import getBodyLayout from './utils';

function HitsContent({
  contentConfig, hitsConfig, moduleId, hideCreatedAtAndStatus,
}) {
  const [availableData, setAvailableData] = useState({});
  const [bodyLayout, setBodyLayout] = useState({});
  const [totalHits, setTotalHits] = useState(0);
  const [currentHitIndex, setCurrentHitIndex] = useState(0);

  const availableRecordData = useSelector((state) => state.appDetails.availableCardData[moduleId]);

  const {
    headerConfig = {}, bodyLayouts = [],
  } = contentConfig;

  const { pathToHits, pathToHitsLength } = hitsConfig;

  useEffect(() => {
    const recordData = get(availableRecordData, 'recordData', {});
    if (!isEmpty(recordData)) {
      setTotalHits(get(recordData, pathToHitsLength, 0));
      setAvailableData({
        ...availableRecordData,
        hitData: get(recordData, `${pathToHits}[${currentHitIndex}]`, {}),
      });
      setCurrentHitIndex(0);
    }
    setBodyLayout(getBodyLayout(availableRecordData, bodyLayouts));
  }, [availableRecordData]);

  useEffect(() => {
    const recordData = get(availableRecordData, 'recordData', {});
    if (!isEmpty(recordData)) {
      setAvailableData({
        ...availableRecordData,
        hitData: get(recordData, `${pathToHits}[${currentHitIndex}]`, {}),
      });
    }
  }, [currentHitIndex]);

  return (
    <div className="card_type_five__content__container">
      {!hideCreatedAtAndStatus && (
      <Header
        headerConfig={headerConfig}
        availableData={availableData}
      />
      )}
      <Body bodyLayout={bodyLayout} availableData={availableData} />
      <Footer
        currentHitIndex={currentHitIndex}
        totalHits={totalHits}
        setCurrentHitIndex={setCurrentHitIndex}
        availableData={availableData}
        moduleId={moduleId}
      />
    </div>
  );
}

HitsContent.defaultProps = {
  hideCreatedAtAndStatus: false,
};

HitsContent.propTypes = {
  contentConfig: PropTypes.object.isRequired,
  hitsConfig: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default HitsContent;
