import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Body from './Body/Body';
import Header from './Header/Header';
import getBodyLayout from './utils';

function Content({ contentConfig, moduleId, hideCreatedAtAndStatus }) {
  const {
    headerConfig = {}, bodyLayouts = [],
  } = contentConfig;

  const availableRecordData = useSelector((state) => state.appDetails.availableCardData[moduleId]);

  const [bodyLayout, setBodyLayout] = useState({});

  useEffect(() => {
    setBodyLayout(getBodyLayout(availableRecordData, bodyLayouts));
  }, [availableRecordData]);

  return (
    <div className="card_type_five__content__container">
      {!hideCreatedAtAndStatus && (
      <Header
        headerConfig={headerConfig}
        availableData={availableRecordData}
      />
      )}
      <Body bodyLayout={bodyLayout} availableData={availableRecordData} />
    </div>
  );
}

Content.defaultProps = {
  hideCreatedAtAndStatus: false,
};

Content.propTypes = {
  contentConfig: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default Content;
