import axios from 'axios';
import * as Sentry from '@sentry/react';

import { get, set } from 'lodash';
import googleIcon from '../../assests/images/google.svg';
import productRedirectFunctions from '../../utils/productRedirectFunctions';

import {
  updateCredState,
  updateEmail, updateIsAuthenticated,
} from '../../reducers/user';
import AlertCode from '../../constants/alertCodes';
import { startEmailOtpAPI } from '../../api/mfa';
import { getRedirectURLFromQueryParams, isNumber } from '../../utils/helpers';
import { PERFORMANCE_METRIC_EVENTS } from '../../config';

export const sendOtp = async (setError, setErrorMessage) => {
  try {
    await startEmailOtpAPI();
  } catch (err) {
    setError(true);
    const code = get(err, 'response.data.code');
    if (code === 'mfa/otp-rate-limit') {
      setErrorMessage('Try again after sometime');
      return;
    }
    if (code === 'mfa/invalid-token' || code === 'mfa/expired-token') {
      setErrorMessage('MFA Session expired');
      return;
    }
    setErrorMessage('Something went wrong');
    Sentry.captureException(`${code} - ${err}`, {
      extra: {
        errorMessage: AlertCode.ERROR_SENDING_OTP,
      },
    });
  }
};

const authenticateUser = async (
  dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
  formatPerformanceAnalyticsData,
) => {
  const startTime = performance.now();
  const eventObj = {
    APIEndPoint: 'api/v1/user/login',
    metaData: {},
  };
  try {
    const response = await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/login`,
      data: {
        email,
        password,
      },
    });
    if (isNumber(response?.status)) set(eventObj, 'statusCode', response?.status);

    const { isPortalUser, authorizedProducts = [] } = response.data.result;
    dispatch(updateIsAuthenticated(isPortalUser));
    const loginApiEndTime = performance.now() - startTime;
    set(eventObj.metaData, 'loginApiEndTimeaOnSuccess', `${loginApiEndTime.toFixed(2)}ms (${(loginApiEndTime / 1000).toFixed(2)}s)`);

    if (!isPortalUser) {
      dispatch(updateEmail(''));
      const productToRedirectTo = authorizedProducts.find(
        (authorizedProduct) => authorizedProduct in productRedirectFunctions,
      );
      if (productToRedirectTo) {
        productRedirectFunctions[productToRedirectTo](navigate);
      } else if (!queryParams.has('redirect')) {
        setOpenInputAlert(true);
        setErrorMessage(AlertCode.auth.unauthorizedProduct);
      }
    }
    const processingTime = performance.now() - startTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.USER_LOGIN;
    formatPerformanceAnalyticsData(eventObj, eventName);
    return isPortalUser;
  } catch (error) {
    const loginApiEndTime = performance.now() - startTime;
    set(eventObj.metaData, 'loginApiEndTimeOnFailure', `${loginApiEndTime.toFixed(2)}ms (${(loginApiEndTime / 1000).toFixed(2)}s) ${loginApiEndTime}`);
    const errorCode = get(error, 'response.data.errorCode');
    const statusCode = get(error, 'response.status');
    if (errorCode === 'mfa/not-verified' || errorCode === 'mfa/no-factors-registered') {
      const sessionStorageEmailStartTime = performance.now();
      window.sessionStorage.setItem('email', email);
      const sessionStorageEmailTimeTaken = performance.now()
      - sessionStorageEmailStartTime;
      set(
        eventObj.metaData,
        'sessionStorageEmailTimeTaken',
        `${sessionStorageEmailTimeTaken.toFixed(2)}ms (${(sessionStorageEmailTimeTaken / 1000).toFixed(2)}s) ${sessionStorageEmailTimeTaken}`,
      );

      const sessionStoragePasswordStartTime = performance.now();
      window.sessionStorage.setItem('password', password);
      const sessionStoragePasswordTimeTaken = performance.now()
      - sessionStoragePasswordStartTime;
      set(
        eventObj.metaData,
        'sessionStoragePasswordTimeTaken',
        `${sessionStoragePasswordTimeTaken.toFixed(2)}ms (${(sessionStoragePasswordTimeTaken / 1000).toFixed(2)}s) ${sessionStoragePasswordTimeTaken}`,
      );

      const sendOtpStartTime = performance.now();
      await sendOtp(setOpenInputAlert, setErrorMessage);
      const sendOtpTimeTaken = performance.now() - sendOtpStartTime;
      set(
        eventObj.metaData,
        'sendOtpTimeTaken',
        `${sendOtpTimeTaken.toFixed(2)}ms (${(sendOtpTimeTaken / 1000).toFixed(2)}s) ${sendOtpTimeTaken}`,
      );

      const setProcessingTimeStartTime = performance.now();
      const processingTime = performance.now() - startTime;
      set(eventObj, 'processingTime', processingTime);
      const setProcessingTimeTimeTaken = performance.now() - setProcessingTimeStartTime;
      set(
        eventObj.metaData,
        'setProcessingTimeTimeTaken',
        `${setProcessingTimeTimeTaken.toFixed(2)}ms (${(setProcessingTimeTimeTaken / 1000).toFixed(2)}s) ${setProcessingTimeTimeTaken}`,
      );
      const eventName = PERFORMANCE_METRIC_EVENTS.USER_REDIRECT_TO_MFA_PAGE;
      formatPerformanceAnalyticsData(eventObj, eventName);
      navigate(`/mfa/email/otp/verify?${queryParams.toString()}`);
    } else if (statusCode === 403) {
      setOpenInputAlert(true);
      if (errorCode === 'auth/user-disabled') {
        setErrorMessage(AlertCode.auth.userDisabled);
      } else if (errorCode === 'auth/company-inactive') {
        setErrorMessage(AlertCode.auth.companyInactive);
      }
    } else if (statusCode === 400 || statusCode === 401) {
      setOpenInputAlert(true);
      setErrorMessage('Invalid Email / Password');
    } else {
      setOpenInputAlert(true);
      Sentry.captureException(`${errorCode} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_AUTHENTICATE_USER,
        },
      });
      setErrorMessage(AlertCode.SOMETHING_WENT_WRONG);
    }
    dispatch(updateEmail(''));
    dispatch(updateIsAuthenticated(false));
    const processingTime = performance.now() - startTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.USER_LOGIN;
    if (isNumber(statusCode)) set(eventObj, 'statusCode', statusCode);
    formatPerformanceAnalyticsData(eventObj, eventName);
    return false;
  }
};

const redirectFromQueryParams = (navigate, queryParams) => {
  const redirectUrl = getRedirectURLFromQueryParams(queryParams);
  if (redirectUrl) {
    navigate(`redirect?url=${encodeURIComponent(redirectUrl)}`);
  }
};

const authenticateUserAndHandleRedirect = async (
  dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
  formatPerformanceAnalyticsData,
) => {
  const isValidUser = await authenticateUser(
    dispatch, navigate, setErrorMessage, setOpenInputAlert, email, password, queryParams,
    formatPerformanceAnalyticsData,
  );
  if (isValidUser) redirectFromQueryParams(navigate, queryParams);
};

export const redirectFunction = (dispatch, navigate, auth, queryParams) => {
  if (auth) {
    if (queryParams.has('redirect')) {
      window.location = `${window.location.origin}/redirect?url=${encodeURIComponent(queryParams.get('redirect'))}`;
    } else if (queryParams.has('recordId') && queryParams.has('recordIdType') && queryParams.has('recordPage')) {
      dispatch(updateCredState({ appId: queryParams.get('appid') }));
      navigate(`/${queryParams.get('recordPage')}?${queryParams.get('recordIdType')}=${queryParams.get('recordId')}`, {
        state: {
          searchid: queryParams.get('record'),
        },
      });
    } else {
      navigate('/home');
    }
  }
};

export const startGoogleLogin = async (
  navigate, dispatch, setErrorMessage, setOpenInputAlert, setIsLoading, queryParams,
) => {
  try {
    const baseSSOVerifyURL = `${process.env.REACT_APP_SERVER_URL}/api/v1/sso/google/verify`;

    let successRedirectUrl = `${window.location.origin}/home`;
    if (queryParams.has('recordId') && queryParams.has('recordIdType') && queryParams.has('recordPage')) {
      dispatch(updateCredState({ appId: queryParams.get('appid') }));
      successRedirectUrl = `${window.location.origin}/${queryParams.get('recordPage')}?${queryParams.get('recordIdType')}=${queryParams.get('recordId')}`;
    } else if (queryParams.get('redirect')) {
      successRedirectUrl = `${window.location.origin}/redirect?url=${queryParams.get('redirect')}`;
    }
    let errorRedirectUrl = `${window.location.origin}${window.location.pathname}`;

    successRedirectUrl = `${baseSSOVerifyURL}?successRedirectUrl=${encodeURIComponent(successRedirectUrl)}`;
    errorRedirectUrl = `${baseSSOVerifyURL}?errorRedirectUrl=${encodeURIComponent(errorRedirectUrl)}`;
    const googleAuthRes = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/sso/google/start?successRedirectUrl=${successRedirectUrl}&errorRedirectUrl=${errorRedirectUrl}`,
    });
    const redirectUrl = get(googleAuthRes, 'data.result.redirectUrl');
    if (!redirectUrl) {
      navigate('/');
      setOpenInputAlert(false);
      setErrorMessage('Something went wrong');
    }
    dispatch(updateIsAuthenticated(true));
    window.location.replace(redirectUrl);
  } catch (error) {
    setOpenInputAlert(false);
    setIsLoading(false);
    const errorCode = get(error, 'response.data.code') || get(error, 'response.data.errorCode');
    const statusCode = get(error, 'response.status');
    let errMsg = 'Error authenticating user';
    if (statusCode === 403 && errorCode === 'user/not-found') {
      errMsg = 'Account does not exist. Choose another account or contact admin';
      setErrorMessage(errMsg);
    }
    Sentry.captureException(`${errorCode} - ${error}`, {
      extra: {
        errorMessage: errMsg,
      },
    });
    setErrorMessage('Something went wrong');
  }
};

export function IconLeft({ ...props }) {
  // eslint-disable-next-line react/react-in-jsx-scope, react/jsx-props-no-spreading
  return <img width="20px" height="20px" src={googleIcon} alt="G" {...props} />;
}

export default authenticateUserAndHandleRedirect;
